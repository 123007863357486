<script setup lang="ts">
  import { computed } from 'vue'
  import type { Component } from 'vue'

  const props = withDefaults(
    defineProps<{
      primary?: boolean
      secondary?: boolean
      tertiary?: boolean
      color?: string
      is?: string | Component
    }>(),
    {
      primary: false,
      secondary: false,
      tertiary: false,
      color: undefined,
      is: 'button',
    }
  )

  const colorClass = computed(() => {
    const defaultColor = props.primary || props.secondary ? 'text-cyan' : ''
    return props.color ?? defaultColor
  })
</script>

<!-- eslint-disable vue/v-bind-style -->
<template>
  <component
    :is="is"
    class="base-control hover:opacity-control disabled:not-allowed"
    :class="[
      colorClass,
      {
        'gc-primary': primary,
        'gc-secondary': secondary,
        'gc-tertiary': tertiary,
      },
    ]"
    data-testid="base-button"
    type="button"
  >
    <slot />
  </component>
</template>

<style scoped>
  .gc-primary {
    @apply w-52 min-w-fit rounded-full border-current text-center font-extrabold uppercase;
  }

  .gc-secondary {
    @apply uppercase;
  }

  .gc-tertiary {
    @apply border-gray-var-600 text-gray-var-300 flex items-center gap-2 space-x-2 pl-3;
  }
</style>
